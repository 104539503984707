import { ITranslator } from './translationInterface';
export const translator = (t: (key: string, params?: any) => string): ITranslator => {
return {
amount: () => {return `Amount`},
orders: () => {return `Orders`},
orderDetails: () => {return `Order details`},
surveyOrders: () => {return `Survey Orders`},
productOrders: () => {return `Product Orders`},
billableHoursOrders: () => {return `Billable Hours Orders`},
totalSmsUsage: () => {return `Total sms Usage`},
totalEmailUsage: () => {return `Total email Usage`},
changeLanguage: () => {return `Change language`},
addItem: (item: string) => {return `Add ${item}`},
orgHasNoLicense: () => {return `This organization has no license. A license is required to create orders.`},
createLicenseHelpText: () => {return `Click here to start creating a license.`},
overUsage: (usage: string) => {return `Over usage: ${usage}`},
email: () => {return `Email`},
sms: () => {return `Sms`},
saveItem: (item: string) => {return `Save ${item}`},
surveyDetails: () => {return `Survey details`},
useSurveyQuota: () => {return `Use Survey Quota`},
quotasCannotBeZero: () => {return `At least one quota must be greater than 0`},
emailQuota: () => {return `Email Quota`},
smsQuota: () => {return `Sms Quota`},
surveyQuota: () => {return `Survey Quota`},
description: () => {return `Description`},
notes: () => {return `Notes`},
active: () => {return `Active`},
inactive: () => {return `Inactive`},
renewable: () => {return `Renewable`},
notRenewable: () => {return `Not renewable`},
startDate: () => {return `Start Date`},
usagePeriod: () => {return `Usage period`},
usagePeriodInMonths: () => {return `Usage Period in months`},
usagePeriodInMonthsDescription: (renewable: string) => t("usagePeriodInMonthsDescription", {renewable: renewable}),
billingPeriod: () => {return `Billing period`},
billingPeriodInMonths: () => {return `Billing Period in months`},
billingPeriodInMonthsDescription: () => {return `The number of months which will pass from the start date before the order is billed`},
monthText: (months: string) => t("monthText", {months: months}),
orderEndDateTitle: (renewable: string) => t("orderEndDateTitle", {renewable: renewable}),
orderEndDateDescription: (renewable: string) => t("orderEndDateDescription", {renewable: renewable}),
noEndDate: () => {return `No end date`},
expiredOn: (date: string) => {return `Expired on ${date}`},
invalidDate: (date: string, error: string) => {return `Invalid date: ${date}. Error: ${error}`},
billingDate: () => {return `Billing Date`},
billed: () => {return `Billed`},
dateDifference: (type: string, diff: string) => t("dateDifference", {type: type, diff: diff}),
integerError: (field: string) => {return `${field} must be an integer`},
fieldRequiredError: (field: string) => {return `${field} is required`},
minimumValueError: (field: string, min: string) => {return `${field} must be larger than ${min}`},
maximumValueError: (field: string, max: string) => {return `${field} must not be larger than ${max}`},
oneOffOrderHelperText: () => {return `One Off Orders will be created without usage and billing periods. They will not have an end date.`},
respondentAsRecipientQuestion: () => {return `Set respondent as NPS Notification recipient?`},
respondentAsRecipientWarning: () => {return `Caution: Each respondent will receive an NPS Notification e-mail immediately after completing the survey, provided the NPS criteria are met.`},
npsCriteria: () => {return `NPS Criteria`},
npsNotification: () => {return `NPS Notification`},
notificationChannel: () => {return `Notification Channel`},
recipientConfiguration: () => {return `Recipient Configuration`},
webhookCall: () => {return `Webhook call`},
webhookUrl: () => {return `Webhook URL`},
singleEmail: () => {return `Single e-mail`},
emailToSender: () => {return `E-mail to sender`},
emailToRespondentOwner: () => {return `E-mail to respondent owner`},
emailToCampaignResponsible: () => {return `E-mail to responsible on recipient`},
emailToRespondent: () => {return `E-mail to respondent`},
emailAddress: () => {return `E-mail Address`},
deleteNotificationTriggerTitle: () => {return `Delete NPS Notification trigger?`},
deleteNotificationTriggerDescription: () => {return `Are you sure you want to delete the NPS Notification trigger?`},
test: () => {return `Test`},
notificationTriggers: () => {return `NPS Notification triggers`},
notificationTriggersDescription: () => {return `Set up NPS Notifications to be notified when a respondent gives a certain response to a campaign. This enables you to quickly take action on customer feedback.`},
notificationTriggerSenderDescription: () => {return `For e-mail notifications, the sender's address is determined by the type of recipient. E-mails sent to the <i>Respondent<i> use the sender defined in the campaign, while all other emails are sent from no-reply@npstoday.com.`},
addNotificationTrigger: () => {return `Add NPS Notification trigger`},
to: () => {return `to`},
userDetails: () => {return `User Details`},
personalInformation: () => {return `Personal Information`},
name: () => {return `Name`},
username: () => {return `Username`},
phoneNumber: () => {return `Phone Number`},
address: () => {return `Address`},
details: () => {return `Details`},
emailConfirmed: () => {return `Email Confirmed`},
created: () => {return `Created`},
unknownDate: () => {return `Unknown date`},
userId: () => {return `User ID`},
role: () => {return `Role`},
companyAdmin: () => {return `Company Admin`},
companyUser: () => {return `Company User`},
superAdmin: () => {return `Super Admin`},
management: () => {return `Management`},
account: () => {return `Account`},
users: () => {return `Users`},
licenses: () => {return `Licenses`},
user: () => {return `user`},
createOrLinkLicenseHelpMessage: () => {return `No license found for the current organization. Assign an existing license to this organization from the list below, or click the edit button in the top right to start creating a new license.`},
assignLicenseHelpMessage: () => {return `Assign an existing license to this organization from the list below.`},
save: () => {return `Save`},
licenseDisplayValue: (customer: string, vat: string) => {return `Customer: ${customer} | VAT: ${vat}`},
noCustomerName: () => {return `No customer name`},
customerName: () => {return `Customer name`},
vatNumber: () => {return `VAT Number`},
countryCode: () => {return `Country Code`},
currency: () => {return `Currency`},
seats: () => {return `Seats`},
licenseDetails: () => {return `License Details`},
licenseCreated: () => {return `License created`},
licenseCreationError: () => {return `Error creating license`},
licenseUpdated: () => {return `License updated`},
licenseUpdateError: () => {return `Error updating license`},
licenseAssignedToOrg: () => {return `License successfully assigned to current organization`},
licenseAssignmentError: () => {return `Error assigning license`},
reassignLicense: () => {return `Reassign license`},
cancel: () => {return `Cancel`},
imageType: () => {return `Image type`},
universalLinkTitle: () => {return `Universal Link Campaign`},
universalLinkDescription: () => {return `Activate this setting if you want to generate a link to this campaign survey, where the customers can rate without being registered.`},
universalLinkLabel: () => {return `Create universal link`},
autoReloadTitle: () => {return `Auto reload`},
autoReloadDescription: () => {return `Enable this setting to automatically reload the survey 2 seconds after it has been completed. This feature is ideal for setting up a survey station on a tablet, allowing multiple customers to continuously provide their feedback. Note: This setting can only be used in conjunction with a universal link campaign.`},
autoReloadLabel: () => {return `Enable auto reload`},
productConfigurations: () => {return `Product Configurations`},
productConfigurationsDescription: () => {return `Manage configurations for your nps.today products`},
configuration: () => {return `Configuration`},
updateEmployeeAccess: () => {return `Update employee access`},
employeeAccess: () => {return `Employee Access`},
hasAccess: () => {return `Has access`},
firstName: () => {return `First Name`},
lastName: () => {return `Last Name`},
grantAccessToEmployees: () => {return `Grant access to the following employees?`},
revokeAccessFromEmployees: () => {return `Revoke access from the following employees?`},
requestFeedbackAppAccess: () => {return `Feedback App access`},
feedbackAppNotEnabled: () => {return `Feedback App is not enabled for your organization. A request will need to be made to enable it.`},
revokeAccessForAll: () => {return `Revoke access for all`},
revokeAccessForAllDescription: () => {return `Are you sure you want to revoke access for all employees?`},
grantAccessToAll: () => {return `Grant access to all`},
grantAccessToAllDescription: () => {return `Are you sure you want to grant access to all employees?`},
inactiveEmployeeAccess: () => {return `The following employees are inactive and will need to be made active before granting access`},
inactiveEmployeeAccessDescription: () => {return `This will only affect active employees`},
universalLink: () => {return `Universal Link`},
qrGeneratorDialogTitlte: () => {return `QR Code Generator`},
qrCodeLinkDescription: () => {return `When scanning the QR code, the user will be redirected to this link.`},
sizeInPixels: () => {return `Size in pixels`},
qrCodeDamageResistenceLevel: () => {return `Damage Resistance Level`},
qrCodeDamageResistenceLevelLow: () => {return `Low`},
qrCodeDamageResistenceLevelMedium: () => {return `Medium`},
qrCodeDamageResistenceLevelHigh: () => {return `High`},
qrCodeDamageResistenceLevelVeryHigh: () => {return `Very High`},
qrCodeDamageResistenceLevelDescription: () => {return `Choose how resistant the QR code should be to damage or dirt. Higher levels can handle more damage but store less data.`},
qrCodeDamageResistenceLevelDescriptionLow: () => {return `Handles minor damage. Best for clean environments and high data capacity.`},
qrCodeDamageResistenceLevelDescriptionMedium: () => {return `Handles some damage. Good balance for general use.`},
qrCodeDamageResistenceLevelDescriptionHigh: () => {return `Handles moderate damage. Suitable for slightly harsh conditions.`},
qrCodeDamageResistenceLevelDescriptionVeryHigh: () => {return `Handles significant damage. Best for very harsh conditions but reduces data capacity.`},
backgroundColor: () => {return `Background color`},
foregroundColor: () => {return `Foreground color`},
margin: () => {return `Margin`},
qrCodeAddImageToCenterOfQRCode: () => {return `Add image to center of QR code`},
imageHeightInPixels: () => {return `Image height in pixels`},
imageWidthInPixels: () => {return `Image width in pixels`},
clearBackgroundBegindImage: () => {return `Clear background behind image`},
qrGeneratorDisclaimer: () => {return ` Always manually test your QR code before using it commercially. Test it in the environment it is intended for use to ensure optimal performance and reliability. Check for readability under various lighting conditions, on different devices, and from different angles.`},
close: () => {return `Close`},
download: () => {return `Download`},
resetQrCode: () => {return `Reset QR code`},
qrCodeInvalidTitle: () => {return `Invalid QR Code`},
qrCodeInvalidDescription: () => {return `Please adjust the settings and try again.`},
qrCodeInvalidDetailDamageResistenceLevel: () => {return `The damage resistance level might be too low.`},
qrCodeInvalidDetailSize: () => {return `The QR code size might be too small.`},
qrCodeInvalidDetailColor: () => {return `The colors might be too similar. The foreground color must be darker than the background color.`},
qrCodeInvalidDetailImage: () => {return `The embedded image might be too large.`},
generateQrCode: () => {return `Generate QR code`},
employeeAccessDescription: () => {return `Configure which of your employees has access to the Feedback App`},
invalidPhonuNumber: () => {return `Phone number is not valid`},
invalidEmail: () => {return `Email must be a valid email address`},
company: () => {return `Company`},
andNMore: (count: string) => {return `...and ${count} more`},
respondentSpreadSheetUploadSuccessDescription: () => {return `Your Excel sheet with respondent details has been successfully uploaded and is now being processed. The surveys are being prepared and will be dispatched shortly.`},
addRespondentsSpreadsheetDescription: (column: string, url: string, style: string) => {return `To add respondents to the campaign, your file must contain at least one column named&nbsp; '${column}'. Download our template&nbsp;<a href=${url} download style=${style}>here</a>&nbsp;to get started.`},
timeOfDispatch: () => {return `Time of dispatch`},
uploadedRespondents: () => {return `Uploaded respondents`},
total: () => {return `Total`},
invalidRespondents: (count: string) => t("invalidRespondents", {count: count}),
preview: () => {return `Preview`},
sendOutSurvey: () => {return `Send out survey`},
campaigns: () => {return `Campaigns`},
campaign: () => {return `Campaign`},
campaignInformation: () => {return `Campaign information`},
campaignName: () => {return `Campaign name`},
nameOfSmsSender: () => {return `Name of SMS sender`},
smsText: () => {return `SMS text`},
subjectOnEmail: () => {return `Subject on email`},
senderOnEmail: () => {return `Sender on email`},
surveySettings: () => {return `Survey settings`},
uploadRespondents: () => {return `Upload respondents`},
newRespondent: () => {return `New respondent`},
sendOutSurveyType: (type: string) => t("sendOutSurveyType", {type: type}),
nameOfEmailSender: () => {return `Name of email sender`},
campaignSettingsNavToCampaignEdit: (url: string, style: string) => {return `To change any of the campaign settings, please navigate to the <a href=${url} style=${style}>Campaign Editor</a>.`},
blockSendOuts: () => {return `Block send outs`},
blockSendOutsDescription: () => {return `This campaign is set to block send outs. No automatic send outs will occur.`},
duplicatesTitle: () => {return `Duplicates`},
duplicatesDescription: () => {return `This campaign allows duplicates. Respondents may receive multiple surveys.`},
throttlingTitle: () => {return `Throttling`},
throttlingDescription: () => {return `No limits on the number of surveys sent in a given time period.`},
forceAnonymousTitle: () => {return `Force anonymous`},
forceAnonymousDescription: () => {return `All responses are anonymous. Responses are not linkable to respondents.`},
singleSubmitTitle: () => {return `Single submit`},
singleSubmitDescription: () => {return `Only final submissions are saved. Prevents automated replies but can impact response rate.`},
campaignSettingsHeader: () => {return `Campaign settings`},
emailRatingVisibilityHeading: () => {return `Email rating visibility`},
emailRatingVisibilityBody: () => {return `Control the visibility of NPS survey questions and rating buttons in your emails. When disabled, the survey elements like main question and 0-10 boxes will be hidden. Ensure to include the survey URL in the 'Introductory text' using {REPLY_URL} for users to access the survey from the email.`},
emailRatingVisibilityLabel: () => {return `Enable NPS survey question and rating buttons in emails`},
emailRatingVisibilityFurtherInfo: () => {return `This setting is for scenarios where limiting the number of links in the email will impact the delivery rate.`},
blockSendOutsBody: () => {return `This feature will block all send outs. Respondents and data can still be added to the campaign, which enables you to use this feature in a testing scenario.`},
blockSendOutsLabel: () => {return `Block campaign send outs`},
sendoutDelayHeading: () => {return `Sendout delay`},
sendoutDelayBody: () => {return `Specify how much time should pass from a survey being marked as ready to send, to it actually being sent to the respondent. This could be useful when the event that triggers a survey happens before a customer transaction has ended. For example, a customer in a physical store makes a purchase that triggers a survey, but you only want the customer to receive the survey after they have left the store.`},
enableThrottlingHeading: () => {return `Enable throttling`},
enableThrottlingBody: () => {return `When enabling throttling, the system ensures that a survey is only sent to a respondent if they have not received any survey from this or other campaigns within the same throttling group during the specified number of days. By default, all campaigns are part of a common throttling group. If no throttling group is selected, the campaign will be considered independent and only affected by its own throttle settings. It is important to note that throttling is mutual and only affects campaigns within the same group. This means that campaigns in separate throttling groups will not consider each other's sent surveys when applying their respective throttling rules.`},
duplicatesHeading: () => {return `Duplicates`},
duplicatesBody: () => {return `Allow respondents to be added to a campaign multiple times. This is useful for recurring or long-lasting campaigns.`},
duplicatesLabel: () => {return `Allow duplicates`},
emailSubjectRequired: () => {return `E-mail subject is required`},
introTextIncludesReplyUrl: () => {return `Introductory text must include {REPLY_URL} when disabling NPS survey question and rating buttons.`},
questionMinLength: () => {return `Question should be at least 5 characters`},
questionRequired: () => {return `Question is required`},
smsLinkRequired: () => {return `Please add {LINK} to SMS text, and ensure there are whitespaces before and after the link.`},
smsSenderRequired: () => {return `SMS sender is required`},
validEmail: () => {return `Please enter a valid email address`},
validWebhookUrl: () => {return `Please enter a valid webhook URL`},
recipientsOrWebHookUrlRequired: () => {return `Please enter a recipient value`},
throttlingDaysRequired: () => {return `Throttling days is required when throttling is enabled`},
mustBeValidUrl: () => {return `Must be a valid URL`},
externalWebHookUrlRequired: () => {return `External webhook URL is required for External Relay type.`},
authenticationTypeRequired: () => {return `Authentication type is required for External Relay type.`},
usernameRequired: () => {return `Username is required for Basic authentication.`},
passwordRequired: () => {return `Password is required for Basic authentication.`},
apiKeyRequired: () => {return `API key is required.`},
clientIdRequired: () => {return `Client ID is required for OAuth.`},
clientSecretRequired: () => {return `Client Secret is required for OAuth.`},
tokenEndpointRequired: () => {return `Token Endpoint URL is required for OAuth.`},
authenticationSourceRequired: () => {return `Authentication Source is required for OAuth.`},
relaySwitchLabel: () => {return `Relay`},
relayDescription: () => {return `Enabling Relay permits direct control over request handling by redirecting them to your specified webhook. When active, our system will no longer process these requests; instead, they'll be sent directly to your designated endpoint for manual management. This offers enhanced flexibility, allowing for tailored processing in alignment with your system's specific requirements.`},
type: () => {return `Type`},
authentication: () => {return `Authentication`},
none: () => {return `None`},
basic: () => {return `Basic`},
apiKey: () => {return `API Key`},
oAuth: () => {return `OAuth`},
password: () => {return `Password`},
clientId: () => {return `Client ID`},
clientSecret: () => {return `Client Secret`},
tokenEndpointUrl: () => {return `Token Endpoint URL`},
authorizationSource: () => {return `Authorization Source`},
amountOfDaysRequired: () => {return `Amount of days is required`},
automaticAnonymization: () => {return `Automatic Anonymization`},
automaticAnonymizationDescription: () => {return `Safeguard your respondents' privacy by enabling this feature, designed to automatically anonymize their data after a specified number of days of inactivity. Inactivity in this context refers to a period during which a respondent has not participated in any surveys. By setting the desired retention period, you ensure compliance with data privacy regulations while reducing the risk of unauthorized access or misuse of personal information. The anonymized data, while stripped of personally identifiable information, is retained for statistical purposes.`},
anonymizeDataAfter: () => {return `Anonymize data after:`},
years: () => {return `Years`},
days: () => {return `Days`},
humanVerification: () => {return `Human Verification`},
humanVerificationDescription1: () => {return `Enabling Human Verification integrates Turnstile, a security feature similar to reCAPTCHA, directly into your survey page. This mechanism helps to ensure that responses are from real humans, not bots. When this setting is active, an invisible check runs in the background. If the system cannot automatically verify the respondent, they will be prompted to check a checkbox.`},
humanVerificationDescription2: () => {return `This feature is beneficial because it significantly reduces the risk of automated bot responses, enhancing the accuracy and reliability of your survey data. By filtering out potential bot answers, you ensure that the insights drawn from your surveys are based on genuine human feedback.`},
turnstileOverview: (url: string, style: string) => {return `For more information on Turnstile and its benefits, please refer to this <a href=${url} target='__blank' style=${style}>Cloudflare Turnstile Overview</a>.`},
addToPuzzelPGap: () => {return `Add to Puzzel P-Gap`},
puzzelPGapDescription: () => {return `This feature works only in Puzzel and prompts agents to answer this question during an active P-Gap period.`},
phoneWithoutCountryCode: (initialValue: string) => {return `The currently saved phone number is ${initialValue} without a country code. Please select the appropriate country code.`},
useFormattedNumber: (formattedValue: string) => {return `Use ${formattedValue}`},
lessThanThrottling: () => {return `Max days cannot exceed throttling days`},
forwardedCampaignRequired: () => {return `Campaign is required`},
forwardedLabel: () => {return `Send throttled surveys on another campaign`},
forwardedFurtherInfo: () => {return `Enabling this setting ensures that surveys subject to throttling are forwarded to another campaign instead of being withheld.`},
forwardedDaysLabel: () => {return `Max days`},
defineForwardedDays: () => {return `Define max days`},
forwardedDaysFurtherInfo: (forwardedDaysLabelStyle: string, forwardedDaysLabel: string) => {return `Surveys will be redirected to the designated campaign if the specified <span style=${forwardedDaysLabelStyle}>${forwardedDaysLabel}</span> have not passed since the last survey was sent.`},
campaignWithNoName: (campaignId: string) => {return `Campaign with no name - id ${campaignId}`},
productOrderAmountUsedInfo: (amountUsed: string, integrationName: string, totalAmount: string) => {return `${amountUsed} employees have access to the ${integrationName}, out of a total of ${totalAmount} allowed.`},
secret: () => {return `Secret`},
reservedFieldMessage: (field: string) => {return `"${field}" is a reserved field`},
phone: () => {return `Phone`},
externalId: () => {return `External ID`},
responsibleEmployeeEmail: () => {return `Responsible Employee Email`},
field: () => {return `Field`},
value: () => {return `Value`},
uniquePersonalizedLink: () => {return `Unique Personalized Link`},
generateLink: () => {return `Generate Link`},
fullExpandedReport: () => {return `Full Expanded Report`},
fullExpandedReportDescription: () => {return `Separates each Custom Field and all fields from the Advanced Survey Module into distinct columns for detailed analysis. This expanded view ensures easier data review and manipulation, allowing for in-depth insights into survey responses. This report has a limit of 250.000 rows included in the report.`},
fullExpandedReportWithAsm: () => {return `Full Expanded Report - With ASM Question as Header`},
fullExpandedReportWithAsmDescription: () => {return `An extension of the Full Expanded Report, this version includes survey questions as headers in Advanced Survey Module columns, providing clearer context and facilitating easier analysis. This report has a limit of 250.000 rows included in the report.`},
reportEditedResponsesAndRatingsByEmployees: () => {return `Edited responses and ratings by employees`},
npsByCampaign: () => {return `NPS by campaign`},
fullReport: () => {return `Full report (recommended)`},
fullReportDescription: () => {return `This report has a limit of 250.000 rows included in the report.`},
npsByCategory: () => {return `NPS by category`},
npsBySegment: () => {return `NPS by segment`},
npsByIndustry: () => {return `NPS by industry`},
npsByEmployee: () => {return `NPS by employee`},
unansweredOnlyData: () => {return `Unanswered (only data)`},
responsesAndEmployeesOnlyData: () => {return `Responses and employees (only data)`},
responsesAndRespondentsOnlyData: () => {return `Responses and respondents (only data)`},
selectAtLeastOneCampaign: () => {return `Select at least one campaign to include in the report setup`},
respondentMergeInfo: (url: string, style: string) => {return `Need more help? Learn how we identify respondents&nbsp;<a href=${url} target="__blank" style=${style}>here</a>.`},
smsTextLengthCalculation: (dynamicContent: string) => {return `The length of this text message cannot be precisely calculated, as the following content is dynamic: ${dynamicContent}.`},
smsLinkInsertDescription: () => {return `When inserting the SMS link into the survey text, it requires two characters: a whitespace before and after the link. This ensures the link is properly formatted and functional`},
usedSegments: (numberOfSegments: string) => t("usedSegments", {numberOfSegments: numberOfSegments}),
estimated: () => {return `<span>Estimated&nbsp;</span>`},
ratingChange: () => {return `Rating change`},
ratingChangeDescription: () => {return `Allow respondents to change their nps-rating before clicking the submit button.`},
ratingChangeLabel: () => {return `Allow rating change`},
reminders: () => {return `Reminders`},
remindersDescription: () => {return `If a respondent doesn't reply to a campaign, you have the option to send out a reminder. Reminders are sent out after a chosen number of days, but can also be disabled.`},
sendAutomaticReminder: () => {return `Send automatic reminder`},
hours: () => {return `Hours`},
unsubscribe: () => {return `Unsubscribe`},
unsubscribeDescription: () => {return `Allow respondents to unsubscribe from future campaigns. An unsubscribe link will be added to the bottom of the e-mail.`},
unsubscribeLabel: () => {return `Allow unsubscription`},
unsubscribeText: () => {return `Unsubscribe text`},
unsubscribeTextPlaceholder: () => {return `Write a message that the respondent will see after unsubscribing.`},
smsDelivery: () => {return `SMS delivery`},
smsDeliveryDescription: () => {return `Enable this setting if you wish to be able to send out the campaign by SMS. nps.today will not be sending out e-mails if this setting is enabled.`},
smsDeliveryLabel: () => {return `Send survey as sms`},
smsSender: () => {return `SMS sender`},
smsTextPlaceholder: () => {return `Write the text to be send as SMS.`},
consentCollection: () => {return `Consent collection`},
consentCollectionDescription: () => {return `Adds an additional checkbox to collect consent. We suggest that you add this to your own privacy policy.`},
consentCollectionLabel: () => {return `Enable consent collection`},
optIn: () => {return `Opt-in`},
optOut: () => {return `Opt-out`},
optOutDescription: (style: string) => {return `Although the GDPR does not specifically ban opt-out consent, the&nbsp;<a target="_blank" rel="noopener" style=${style} href='https://ico.org.uk/media/about-the-ico/consultations/2013551/draft-gdpr-consent-guidance-for-consultation-201703.pdf'>ICO (Information Commissioner's Office)</a>&nbsp;says that opt-out options “are essentially the same as pre-ticked boxes, which are banned.“ Carefully consider if this option is right for your scenario.”`},
consentText: () => {return `Consent text`},
consentTextPlaceholder: () => {return `I accept that my answer may be used for public display.`},
afterFollowUpQuestion: () => {return `After followup question`},
atTheEndOfTheSurvey: () => {return `At the end of the survey`},
forceAnonymousExplanation: () => {return `Force all responses to be anonymous. Respondents will still exist, but a response is given a respondent which is not linkable to a response or participation.`},
singleSubmitExplanantion: () => {return `Forces the survey to only send the response if the respondent clicks the very last "submit" button of the survey. All recorded data is lost if the last submit button is not clicked. This option can be used to prevent automated replies from spam filters and security systems. Only recommended for B2B scenarios.`},
singleSubmitWarning: () => {return `Note that you may get lower response rates when this is enabled.`},
industry: () => {return `Industry`},
companies: () => {return `Companies`},
deleteCompanies: (companies: string) => t("deleteCompanies", {companies: companies}),
uploadCompanies: () => {return `Upload companies`},
delete: () => {return `Delete`},
deleteCompaniesDescription: (companies: string) => t("deleteCompaniesDescription", {companies: companies}),
makeOrganizationsInactive: () => {return `Make the following organizations inactive?`},
makeOrganizationsActive: () => {return `Make the following organizations active?`},
deleteUserTitle: () => {return `You are about to delete a user`},
deleteUsersDescription: (users: string) => t("deleteUsersDescription", {users: users}),
addNewUser: () => {return `Add new user`},
selectRole: () => {return `Select role`},
addUser: () => {return `Add user`},
anonymizeEmployees: () => {return `Anonymize employees`},
anonymizeCompanies: () => {return `Anonymize companies`},
anonymizeUsers: () => {return `Anonymize users`},
anonymizationExplanation: () => {return `Employees, companies and users will only be anonymized if they are inactive`},
changeAnonymizationConfigurationDescription: (oldYears: string, oldDays: string, oldCombinedDays: string, newYears: string, newDays: string, newCombinedDays: string) => t("changeAnonymizationConfigurationDescription", {oldYears: oldYears, oldDays: oldDays, oldCombinedDays: oldCombinedDays, newYears: newYears, newDays: newDays, newCombinedDays: newCombinedDays}),
disableAutomaticAnonymizationDescription: (oldYears: string, oldDays: string, oldCombinedDays: string) => t("disableAutomaticAnonymizationDescription", {oldYears: oldYears, oldDays: oldDays, oldCombinedDays: oldCombinedDays}),
employeeAnonymizationEnabled: () => {return `Employee anonymization has been enabled. This will affect all inactive employees that are not yet anonymized.`},
companyAnonymizationEnabled: () => {return `Company anonymization has been enabled. This will affect all inactive companies that are not yet anonymized.`},
userAnonymizationEnabled: () => {return `User anonymization has been enabled. This will affect all inactive users that are not yet anonymized.`},
disableAutomaticAnonymization: () => {return `Disable automatic anonymization`},
changeAutomaticAnonymization: () => {return `Change automatic anonymization`},
continue: () => {return `Continue`},
logo: () => {return `Logo`},
displayName: () => {return `Display name`},
updateOrganizations: () => {return `Update organizations`},
ensureValidLicenseDescription: () => {return `This option requires the current organization to have an active license. Please ensure a valid license is associated with this organization to proceed.`},
addLinkedOrganization: () => {return `Add Linked organization`},
addOrganization: () => {return `Add organization`},
onlyAccessibleForSuperAdminsTitle: () => {return `This page is only accessible for <b>Super Admins</b>`},
onlyAccessibleForSuperAdminsDescription: () => {return `This is where you find features that are limited to Super Admins, and may not relate directly to the currently logged in organization`},
campaignIsActive: () => {return `This campaign is active`},
campaignIsClosed: () => {return `This campaign is closed`},
nps: () => {return `NPS`},
respondents: () => {return `Respondents`},
responses: () => {return `Responses`},
responsePercentage: () => {return `Response %`},
reminderDelay: () => {return `Reminder delay`},
throttlingGroup: () => {return `Throttling Group`},
allowDuplicates: () => {return `Allow duplicates`},
question: () => {return `Question`},
linkCampaign: () => {return `Link Campaign`},
emailCampaign: () => {return `E-mail campaign`},
smsCampaign: () => {return `Sms campaign`},
edit: () => {return `Edit`},
editCompany: () => {return `Edit company`},
accountNumber: () => {return `Account number`},
segment: () => {return `Segment`},
address1: () => {return `Address 1`},
address2: () => {return `Address 2`},
zipCode: () => {return `ZIP code`},
country: () => {return `Country`},
team: () => {return `Team`},
title: () => {return `Title`},
department: () => {return `Department`},
division: () => {return `Division`},
employees: () => {return `Employees`},
uploadEmployees: () => {return `Upload employees`},
addEmployee: () => {return `Add Employee`},
deleteEmployeesTitle: (count: string) => t("deleteEmployeesTitle", {count: count}),
deleteEmployeesDescription: (count: string) => t("deleteEmployeesDescription", {count: count}),
deleteXEmployees: (count: string) => t("deleteXEmployees", {count: count}),
}
}