import { ListItem } from '@material-ui/core';
import {
  List,
  ListItemText,
  ListSubheader,
  TextField,
  LinearProgress,
  Divider
} from '@mui/material';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { GroupedMergeFields, MergeField } from '../../../@types/mergeFields';
import useDisplayMessage from 'components/messages/useMessage';
import React, { useState, useEffect } from 'react';
import useCustomDispatch from 'redux/dispatch';
import { getMergeFields } from 'redux/slices/mergeFields';
import { RootState, useSelector } from 'redux/store';

type CampaignBuilderMergeFieldsProps = {
  handleClose: () => void;
};
const CampaignBuilderMergeFields = ({ handleClose }: CampaignBuilderMergeFieldsProps) => {
  const [filterValue, setFilterValue] = useState<string>('');
  const displayMessage = useDisplayMessage();
  const customDispatch = useCustomDispatch();
  const { mergeFields, groupedMergeFields, isLoading } = useSelector(
    (state: RootState) => state.mergeFields
  );
  const [filteredGroupedMergeFields, setFilteredGroupedMergeFields] =
    useState<Partial<GroupedMergeFields>>();

  useEffect(() => {
    if (mergeFields.length > 0) return;
    customDispatch({ action: getMergeFields, disableSuccessMessage: true });
  }, [customDispatch, mergeFields]);

  const getFilterGroupedMergeFields = (searchString: string): Partial<GroupedMergeFields> => {
    if (!groupedMergeFields) return {};

    const filtered = Object.fromEntries(
      Object.entries(groupedMergeFields).reduce<[string, Pick<MergeField, 'name' | 'value'>[]][]>(
        (acc, [category, fields]) => {
          const isCategoryNameMatch = category.toLowerCase().includes(searchString.toLowerCase());
          const filteredFields = isCategoryNameMatch
            ? fields
            : fields.filter(
                (field) =>
                  field.name.toLowerCase().includes(searchString.toLowerCase()) ||
                  field.value.toLowerCase().includes(searchString.toLowerCase())
              );

          // Only add to accumulator if there are fields remaining after filtering
          if (filteredFields.length > 0) {
            acc.push([category, filteredFields]);
          }

          return acc;
        },
        [] // Starting with an empty array as the accumulator
      )
    );

    return filtered as GroupedMergeFields;
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value || '';
    setFilterValue(searchString);
    setFilteredGroupedMergeFields(
      searchString ? getFilterGroupedMergeFields(searchString) : groupedMergeFields
    );
  };

  const groupedMergeFieldsToDisplay = filteredGroupedMergeFields ?? groupedMergeFields;

  return (
    <>
      <Typography sx={{ p: 2 }}>Select option to copy to clipboard</Typography>
      <Box ml={3} mr={3}>
        <TextField fullWidth label={'Search'} value={filterValue} onChange={handleSearch} />
      </Box>
      <List
        sx={{
          width: '100%',
          maxWidth: 400,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          height: 400,
          '& ul': { padding: 0 }
        }}
        subheader={<li />}
      >
        {isLoading.getMergeFields ? (
          <LinearProgress style={{ width: '100%' }} />
        ) : (
          groupedMergeFieldsToDisplay &&
          Object.entries(groupedMergeFieldsToDisplay).map(([groupTitle, fields], index) => (
            <React.Fragment key={index}>
              <ListSubheader>{groupTitle}</ListSubheader>
              {fields.map((field, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    displayMessage.success({ message: { title: 'Copied to clipboard' } });
                    navigator.clipboard.writeText(field.value);
                    handleClose();
                  }}
                >
                  <ListItemText primary={field.name} secondary={field.value} />
                </ListItem>
              ))}
              {index !== Object.entries(groupedMergeFieldsToDisplay).length - 1 && <Divider />}
            </React.Fragment>
          ))
        )}
      </List>
    </>
  );
};

export default CampaignBuilderMergeFields;

/*
 {smsOptions
          .filter((option) =>
            searchOptions && option.key
              ? option.label.toLowerCase().includes(searchOptions.toLowerCase()) ||
                option.key?.toLowerCase().includes(searchOptions.toLowerCase())
              : option
          )
          .map((option, index) => {
            if (option.key) {
              return (
                <ListItemButton
                  key={index}
                  onClick={() => {
                    displayMessage.success({ title: 'Copied to clipboard' });
                    navigator.clipboard.writeText(option?.key);
                    handleClose();
                  }}
                >
                  <ListItemText secondary={option?.key} primary={option.label} />
                </ListItemButton>
              );
            } else {
              return <ListSubheader key={index}>{option.label}</ListSubheader>;
            }
          })}
*/
