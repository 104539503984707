import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';
import {
  EmployeeProductAccessConfigurationDto,
  ProductConfigurationDataState
} from '../../@types/productConfiguration';

const initialState: ProductConfigurationDataState = {
  isLoading: false,
  isDeleteLoading: false,
  configurationData: { count: 0, configurations: [] },
  configuration: undefined,
  isConfigurationLoading: false
};

const slice = createSlice({
  name: 'productConfigurations',
  initialState,
  reducers: {
    // General use
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    stopDeleteLoading(state) {
      state.isDeleteLoading = false;
    },

    addConfigurationSuccess(state, action) {
      state.isLoading = false;
    },

    startGetConfiguration(state) {
      state.isConfigurationLoading = true;
    },

    stopIsConfigurationLoading(state) {
      state.isConfigurationLoading = false;
    },

    getConfigurationsSuccess(state, action) {
      state.isLoading = false;
      state.configurationData.count = action.payload.count;
      state.configurationData.configurations = action.payload;
    },

    editConfigurationSuccess(state, action) {
      const newConfiguration = JSON.parse(action.payload.config.data);
      state.configurationData.configurations = state.configurationData.configurations.map((o) =>
        newConfiguration.id === o.id ? newConfiguration : o
      );
      state.isLoading = false;
    },
    setConfiguration(state, action) {
      state.configuration = action.payload;
      state.isConfigurationLoading = false;
    },
    resetConfigurations(state) {
      state.configurationData.count = 0;
      state.configurationData.configurations = [];
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setConfiguration } = slice.actions;

// ----------------------------------------------------------------------

export function resetConfigurations() {
  dispatch(slice.actions.resetConfigurations());
}

const accessEndpoint = '/product-access-configurations/employee';

type GetConfigurationProps = {
  id: string;
};
export const getConfiguration = ({ id }: GetConfigurationProps) => {
  return async () => {
    dispatch(slice.actions.startGetConfiguration());
    try {
      const response = await axios.get(`${accessEndpoint}/${id}`);

      dispatch(slice.actions.setConfiguration(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Configuration fetched'
      });
    } catch (error) {
      dispatch(slice.actions.stopIsConfigurationLoading());
      dispatch(slice.actions.setConfiguration(undefined));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch configuration'
      });
    }
  };
};

export function getConfigurations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(accessEndpoint);
      dispatch(slice.actions.getConfigurationsSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Configurations fetched'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch configurations'
      });
    }
  };
}

type EditConfigurationProps = {
  configuration: EmployeeProductAccessConfigurationDto;
};
export function editConfiguration({ configuration }: EditConfigurationProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.put(accessEndpoint, configuration);
      dispatch(slice.actions.editConfigurationSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Configuration updated'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not update configuration'
      });
    }
  };
}

type AddConfigurationProps = {
  configuration: EmployeeProductAccessConfigurationDto;
};
export function addConfiguration({ configuration }: AddConfigurationProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.post(accessEndpoint, configuration);
      dispatch(slice.actions.addConfigurationSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Configuration added'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not add configuration'
      });
    }
  };
}

type GrantAllAccessProps = {
  configuration: EmployeeProductAccessConfigurationDto;
};
export function grantAccessToAll({ configuration }: GrantAllAccessProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.put(`${accessEndpoint}/grant-all`, configuration);
      dispatch(slice.actions.addConfigurationSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Access granted to all active employees'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not grant access to all employees'
      });
    }
  };
}

type RevokeAllAccessProps = {
  configuration: EmployeeProductAccessConfigurationDto;
};
export function revokeAccessFromAll({ configuration }: RevokeAllAccessProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.put(`${accessEndpoint}/revoke-all`, configuration);
      dispatch(slice.actions.addConfigurationSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Access revoked from all employees'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not revoke access from all employees'
      });
    }
  };
}
