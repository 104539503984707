import useDisplayMessage from 'components/messages/useMessage';
import { License } from '../@types/license';
import useAuth from 'hooks/useAuth';
import { ReactNode, createContext, useEffect, useState } from 'react';
import axios from 'utils/axios';
import { useTranslator } from 'translation/useTranslator';

type AssignLicenseToOrgParams = {
  licenseId: string;
};

type CreateNewLicenseParams = { license: Partial<License>; orgId?: string };
type EditLicenseParams = {
  license: License;
  orgId?: string;
};

type LicenseContextProps = {
  license: License | undefined;
  licenseLoading: boolean;
  createNewLicense: (params: CreateNewLicenseParams) => Promise<void>;
  editLicense: (params: EditLicenseParams) => Promise<void>;
  assignLicenseToOrg: (params: AssignLicenseToOrgParams) => Promise<void>;
};

const initialState: LicenseContextProps = {
  license: undefined,
  licenseLoading: true,
  createNewLicense: async () => {},
  editLicense: async () => {},
  assignLicenseToOrg: async () => {}
};

const LicenseContext = createContext(initialState);

type LicenseProviderProps = {
  children: ReactNode;
};

function LicenseProvider({ children }: LicenseProviderProps) {
  const translator = useTranslator();
  const displayMessage = useDisplayMessage();
  const [license, setLicense] = useState<License | undefined>();
  const [licenseLoading, setLicenseLoading] = useState(false);
  const { selectedOrg, rerenderOnOrgChange } = useAuth();

  const getLicense = async () => {
    try {
      setLicenseLoading(true);
      const response = await axios.get('/license');
      setLicense(response.data);
      setLicenseLoading(false);
    } catch (error: any) {
      setLicenseLoading(false);
      setLicense(undefined);
    }
  };

  useEffect(() => {
    if (!selectedOrg?.organizationId) {
      return;
    }

    getLicense();
  }, [selectedOrg?.organizationId, rerenderOnOrgChange]);

  const createNewLicense = async ({ license, orgId }: CreateNewLicenseParams) => {
    try {
      setLicenseLoading(true);
      await axios.post('license', { ...license, orgId: orgId ? [orgId] : undefined });
      displayMessage.success({
        message: {
          title: translator.licenseCreated()
        }
      });
      getLicense();
    } catch (error: any) {
      displayMessage.error({
        error: {
          defaultErrorMessage: translator.licenseCreationError(),
          error: error
        }
      });
      setLicenseLoading(false);
      throw error;
    }
  };

  const editLicense = async ({ license, orgId }: EditLicenseParams) => {
    try {
      setLicenseLoading(true);
      await axios.put('license', { ...license, orgId: orgId ? [orgId] : undefined });
      displayMessage.success({
        message: {
          title: translator.licenseUpdated()
        }
      });
      getLicense();
    } catch (error: any) {
      displayMessage.error({
        error: {
          defaultErrorMessage: translator.licenseUpdateError(),
          error: error
        }
      });
      setLicenseLoading(false);
      throw error;
    }
  };

  const assignLicenseToOrg = async ({ licenseId }: AssignLicenseToOrgParams) => {
    try {
      setLicenseLoading(true);
      await axios.put(`license/org/${licenseId}`, {});
      displayMessage.success({
        message: {
          title: translator.licenseAssignedToOrg()
        }
      });
      getLicense();
    } catch (error: any) {
      displayMessage.error({
        error: {
          defaultErrorMessage: translator.licenseAssignmentError(),
          error: error
        }
      });
      setLicenseLoading(false);
      throw error;
    }
  };

  return (
    <LicenseContext.Provider
      value={{
        license: license,
        licenseLoading,
        createNewLicense,
        editLicense,
        assignLicenseToOrg
      }}
    >
      {children}
    </LicenseContext.Provider>
  );
}

export { LicenseProvider, LicenseContext };
