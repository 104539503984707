import axios from 'axios';
import releaseConfig from '../releaseConfig';
import { LogoutParams } from '../@types/authentication';

//callback method to be called when the user is logged out, this is used to redirect the user to the login page
//the value is set in the App.tsx file
let logoutCallback: ((params?: LogoutParams) => Promise<void>) | null = null;
export const setLogoutCallback = (logoutFn: () => Promise<void>) => {
  logoutCallback = logoutFn;
};

const axiosInstance = axios.create({
  baseURL: releaseConfig.apiUrl.startsWith('#{')
    ? process.env.REACT_APP_API_URL || 'https://dev.api.nps.today'
    : releaseConfig.apiUrl
});

// Add a request interceptor to include the timezone header
axiosInstance.interceptors.request.use(
  (config) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    config.headers['User-Timezone'] = timezone;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check if 401 error - auto logout if it is
    if (error.response && error.response.status === 401) {
      logoutCallback?.({
        logoutInfoMessage: {
          title: 'Session Expired',
          description: 'Your session has expired. Please log in again to continue.'
        }
      });
    }

    return Promise.reject(
      (error.response && error.response.data) || error.message || 'Something went wrong'
    );
  } // TODO get new token with refresh token
);

export default axiosInstance;

export const axiosEtrack1Instance = axios.create({
  baseURL: releaseConfig.etrack1Url.startsWith('#{')
    ? 'https://etrack1dev.azurewebsites.net' //'https://etrack1.nps.today'
    : releaseConfig.etrack1Url
});

export const axiosHtmlFilesInstance = axios.create({
  baseURL: releaseConfig.htmlFilesUrl.startsWith('#{')
    ? 'https://npstodaydevelopment.blob.core.windows.net/html-files'
    : releaseConfig.htmlFilesUrl
});
