import Router from './routes';
import useAuth from './hooks/useAuth';
import { setupAppInsights } from 'appInsights';
import { useEffect } from 'react';
import { registerLicenses } from 'utils/licenses';
import AwaitLoading from 'components/util/AwaitLoading';
import { setLogoutCallback } from 'utils/axios';
import { MsalProvider } from '@azure/msal-react';
import { AuthProvider } from 'contexts/JWTContext';
import { LicenseProvider } from 'contexts/LicenseContext';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'authConfig';
import FloatingActionButtons from 'layouts/dashboard/FloatingActionButtons/FloatingActionButtons';
import { useLocation } from 'react-router';

setupAppInsights();

const AdminApp = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  msalInstance.addEventCallback((event: any) => {
    if (
      (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.LOGIN_SUCCESS) &&
      event.payload.account
    ) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <LicenseProvider>
          <AdminAppCore />
        </LicenseProvider>
      </AuthProvider>
    </MsalProvider>
  );
};

export default AdminApp;

const AdminAppCore = () => {
  const { isInitialized, rerenderOnOrgChange, isDataReflectingCurrentOrg, logout } = useAuth();

  useEffect(() => {
    registerLicenses();
  }, []);

  useEffect(() => {
    setLogoutCallback(logout);
  }, [logout]);

  const location = useLocation();
  const isNotFoundPage = location.pathname === '/404';

  return (
    <>
      {isInitialized && <Router key={rerenderOnOrgChange} />}
      <AwaitLoading
        isLoading={!isNotFoundPage && (!isInitialized || !isDataReflectingCurrentOrg)}
      />
      <FloatingActionButtons />
    </>
  );
};
